






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Order from '@/models/model';
import OrderCard from '@/components/Account/OrderCard.vue';
import admin from '@/store/admin';
import countries from '@/helper/countries';
import toaster, { ToastImpl } from '@/store/toaster';

@Component({ components: { OrderCard } })
export default class OrderDetails extends Vue {

    @Prop()
    order!: Order;

    @Prop()
    adminOrdersMode?: boolean;

    @Prop()
    noCard?: boolean;

    cancel(): void {
        admin.editedOrder = undefined;
        this.$router.go(-1);
    }

    getCountryName(id: string): string {
      const country = countries.getCountries().find(c => c.id === id);
      return country ? country['name' + this.langSuffix] : id;
    }

    viewMoneticoDetails(): void {
        var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.moneticoDetailsModal);
        modal.show();
    }

    saveComment(): void {
        admin.dispatchUpdateOrderComment({ orderId: this.order.id, comment: this.order.comment }).then((r) => {
            if (r) {
                toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.saved')}`, `${this.$t('common.commentSaved')}`));
            }
        });
    }

    get cancellable(): boolean {
        if (!this.order) {
            return false;
        }

        if (this.order.endedOn) {
            return false;
        }

        const rentDates = this.order.orderProducts.map(p => p.endDate);
        const maxDate = rentDates.reduce((a, b) => a > b ? a : b);
        const d = new Date();
        const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        if (maxDate < currentDate) {
            return false;
        }

        return true;
    }

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }
}
