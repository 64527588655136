import Order, { Accessory, Banner, GlobalPromotion, Product, PromotionCode, Season, User } from "@/models/model";
import Api, { getHeaders } from "./api";

export default class {

    static getOrders(): Promise<Order[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/order', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getOrderDetails(orderId: string): Promise<Order & Error> {
        return fetch(Api.apiUrl + 'v1/admin/order/' + orderId, {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addOrder(order: Order): Promise<Order & Error> {
        return fetch(Api.apiUrl + 'v1/admin/order', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(order)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateOrder(order: Order): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/order', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(order)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateOrderTreatedStatus(orderId: string, treated: boolean): Promise<any & Error> {
        return fetch(Api.apiUrl + 'v1/order/' + orderId + '/treated/' + treated, {
            method: 'PATCH',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateOrderComment(orderId: string, comment: string): Promise<any & Error> {
        return fetch(Api.apiUrl + 'v1/order/' + orderId + '/comment', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(comment)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static deleteOrder(orderId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/order/' + orderId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getPromotions(): Promise<GlobalPromotion[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/promotion', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addPromotion(promotion: GlobalPromotion): Promise<GlobalPromotion & Error> {
        return fetch(Api.apiUrl + 'v1/admin/promotion', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(promotion)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updatePromotion(promotion: GlobalPromotion): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/promotion', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(promotion)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static deletePromotion(promotionId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/promotion/' + promotionId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getCodes(): Promise<PromotionCode[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/code', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addCode(code: PromotionCode): Promise<PromotionCode & Error> {
        return fetch(Api.apiUrl + 'v1/admin/code', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(code)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateCode(code: PromotionCode): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/code', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(code)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static deleteCode(codeId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/code/' + codeId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getBanners(): Promise<Banner[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/banner', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addBanner(banner: Banner): Promise<Banner & Error> {
        return fetch(Api.apiUrl + 'v1/admin/banner', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(banner)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateBanner(banner: Banner): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/banner', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(banner)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static deleteBanner(bannerId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/banner/' + bannerId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }


    static getSeasons(): Promise<Season[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/season', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static addSeason(season: Season): Promise<Season & Error> {
        return fetch(Api.apiUrl + 'v1/admin/season', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(season)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateSeason(season: Season): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/season', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(season)
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static deleteSeason(seasonId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/season/' + seasonId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getUsers(): Promise<User[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/user', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static getRoles(): Promise<string[] & Error> {
        return fetch(Api.apiUrl + 'v1/admin/user/role', {
            method: 'GET',
            headers: getHeaders()
        })
        .then((r) => {
            if (!r.ok) {
                return { error: r.statusText };
            }
            return r.json();
        })
        .catch(r => {
            return { error: (r.message ? r.message : r )};
        });
    }

    static updateUser(user: User): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/user', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(user)
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static deleteUser(userId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/user/' + userId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static addProduct(product: Product): Promise<Product & Error> {
        return fetch(Api.apiUrl + 'v1/admin/product', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(product)
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static updateProduct(product: Product): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/product', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(product)
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static deleteProduct(productId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/product/' + productId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static addAccessory(accessory: Accessory): Promise<Accessory & Error> {
        return fetch(Api.apiUrl + 'v1/admin/accessory', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(accessory)
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static updateAccessory(accessory: Accessory): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/accessory', {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(accessory)
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static deleteAccessory(accessoryId: string): Promise<boolean & Error> {
        return fetch(Api.apiUrl + 'v1/admin/accessory/' + accessoryId, {
            method: 'DELETE',
            headers: getHeaders()
        })
        .catch(r => {
            return { error: r.message };
        })
        .then((r: any) => {
            if (r.ok) {
                return r.json();
            }
            return r;
        });
    }

    static addRole(userId: string, role: string): Promise<any & Error> {
        return fetch(Api.apiUrl + 'v1/admin/user/role', {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({userId, role})
        })
        .catch(r => {
            return { error: r.message };
        });
    }

}
