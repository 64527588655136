
































































import { Component, Vue } from 'vue-property-decorator';
import EditTable from '@/components/Admin/EditTable.vue';
import Order, { DepositState, Season, TableColumnDefinition } from '@/models/model';
import admin from '@/store/admin';
import user from '@/store/user';

enum ViewMode {
  Pending = 1,
  Past = 2,
  Canceled = 3
}

@Component({
  components: {
    EditTable
  },
})
export default class Orders extends Vue {

    ViewMode = ViewMode;

    // Todo columns label = i18n key
    private columns: TableColumnDefinition[] = [
        { propertyName: 'treated', label: 'common.treated', type: 'checkbox', onChange: this.toggleTreated },
        { propertyName: 'number', label: '#' },
        { propertyName: 'user.firstName', label: 'view.rent.firstName' },
        { propertyName: 'user.lastName', label: 'view.rent.lastName' },
        { propertyName: 'user.email', label: 'view.account.email' },
        // { propertyName: 'globalPromotion.label', label: 'view.cart.promo' },
        // { propertyName: 'promotionCode.label', label: 'view.cart.code' },
        { propertyName: 'price', label: 'view.account.total', type: 'Money' },
        { propertyName: 'createdOn', label: 'common.createdOn' },
        { propertyName: 'dates', label: 'common.dates' },
        { propertyName: 'comment', label: 'view.account.comment' },
    ];
    private columnsCanceled: TableColumnDefinition[] = [
        { propertyName: 'treated', label: 'common.treated', type: 'checkbox', onChange: this.toggleTreated },
        { propertyName: 'number', label: 'view.account.orderNo' },
        { propertyName: 'user.firstName', label: 'view.rent.firstName' },
        { propertyName: 'user.lastName', label: 'view.rent.lastName' },
        { propertyName: 'user.email', label: 'view.account.email' },
        { propertyName: 'globalPromotion.label', label: 'view.cart.promo' },
        { propertyName: 'promotionCode.label', label: 'view.cart.code' },
        { propertyName: 'price', label: 'view.account.total', type: 'Money' },
        { propertyName: 'createdOn', label: 'common.createdOn' },
        { propertyName: 'dates', label: 'common.dates' },
        { propertyName: 'endedOn', label: 'common.canceledOn' }
    ];
    private viewMode = ViewMode.Pending;
    private seasons: Season[] = [];
    private selectedSeason: Season = ({} as any);

    mounted(): void {
        if (!this.manager) {
          this.$router.push('/');
        }

        this.initSeasons();

        admin.dispatchLoadOrders().then(() => this.orders.forEach(o => (o as any).dates = Array.from(new Set(o.orderProducts.map(p => p.startDate.toLocaleDateString() + ' - ' + p.endDate.toLocaleDateString()))).join(', ')));
    }

    toggleTreated(row: Record<string, unknown>, value: Record<string, unknown>): void {
      admin.dispatchUpdateOrderTreatedStatus({ orderId: row.id, treated: value });
    }

    initSeasons(): void {
      const startYear = 2021;
      const date = new Date();
      const currentYear = date.getFullYear() - (date.getMonth() <= 6 ? 1 : 0);
      const years = [...Array(currentYear - startYear).keys()];
      for (let i = years.length; i >= 0; i--) {
        const currentSeason = startYear + i;
        this.seasons.push({
          id: i.toString(),
          name: currentSeason + '/' + (currentSeason + 1),
          startDate: new Date(currentSeason, 8, 1),
          endDate: new Date(currentSeason + 1, 7, 30),
          active: true
        });
      }

      this.selectedSeason = this.seasons[0];
    }

    getOrderStatus(order: Order): string {
        if (order.endedOn) {
            return 'canceled';
        }

        const rentDates = order.orderProducts.map(p => p.endDate);
        const maxDate = rentDates.reduce((a, b) => a > b ? a : b);
        const d = new Date();
        const currentDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        if (maxDate < currentDate) {
            return 'past';
        }

        return 'booked';
    }

    get orders(): Order[] {
      let orders = admin.orders;

      if (this.selectedSeason && this.selectedSeason.name) {
        orders = orders.filter(o => o.orderProducts && o.orderProducts.length ?
          o.orderProducts[0].startDate >= this.selectedSeason.startDate && o.orderProducts[0].endDate <= this.selectedSeason.endDate :
          o.createdOn >= this.selectedSeason.startDate && o.createdOn <= this.selectedSeason.endDate);
      }

      orders = orders.filter(o => o.depositState == DepositState.None || o.depositState === DepositState.Successfull);

      return orders.sort((a, b) => a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0);
    }

    get pendingOrders(): Order[] {
      return this.orders.filter(o => this.getOrderStatus(o) === 'booked');
    }

    get pastOrders(): Order[] {
      return this.orders.filter(o => this.getOrderStatus(o) === 'past');
    }

    get canceledOrders(): Order[] {
      return this.orders.filter(o => this.getOrderStatus(o) === 'canceled');
    }

    get manager(): boolean {
      return !!user.currentUser && !!user.currentUser.roles
        && (user.currentUser.roles.indexOf("Manager") !== -1 || user.currentUser.roles.indexOf("Admin") !== -1);
    }
}
