export interface Product {
    [key: string]: any;
    id: string;
    label: string;
    description: string;
    labelEn: string;
    descriptionEn: string;
    type: ProductType;
    pictures: Picture[];
    rentPrices: RentPrice[];
    packages: Package[];
    color: string;
    unavailabilityDates: DateRange[];
    unavailabilityProductOnly: boolean;

    selectedPackage?: number;
    shownPicture?: number;
}

export interface Accessory {
    [key: string]: any;
    id: string;
    label: string;
    labelEn: string;
    freeUnderAge: number;
    hasSize: boolean;
    sizes: number[];
    rentPrices: RentPrice[];
    pictures: Picture[];

    selected: boolean;
    selectedSize: number;
}

export interface GlobalPromotion {
    [key: string]: any;
    id: string;
    label: string;
    labelEn: string;
    startDate: Date;
    endDate: Date;
    promotion: number;
    minRentDays: number;
}

export interface PromotionCode {
    [key: string]: any;
    id: string;
    label: string;
    labelEn: string;
    dates: DateRange[];
    code: string;
    promotion: number;
    minRentDays: number;
}

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export interface Order {
    id: string;
    number: number;
    userId: string;
    treated: boolean;
    globalPromotionId: string;
    promotionCodeId: string;
    basePrice: number;
    price: number;
    deposit: number;
    depositState: DepositState;
    depositFlowHistory: any[];
    depositFlowStartDate?: Date;
    moneticoReference: string;
    orderProducts: OrderProduct[];
    createdOn: Date;
    endedOn: Date;
    comment: string;

    globalPromotion?: GlobalPromotion;
    promotionCode?: PromotionCode;
}

export interface OrderProduct {
    productId: string;
    startDate: Date;
    endDate: Date;
    startDateStr?: string;
    endDateStr?: string;
    firstName: string;
    lastName: string;
    gender: string;
    age: number;
    weight: number;
    height: number;
    bootSize: number;
    package: Package;
    productAccessories: OrderProductAccessory[];

    product: Product;
}

export interface OrderProductAccessory {
    accessoryId: string;
    quantity: number;
    size: number;
}

export interface Picture {
    hash: string;
    title: string;
    name: string;
    size: number;
    createdOn: Date;
    description: string;
}

export interface RentPrice {
    days: number;
    price: number;
}

export interface Package {
    [key: string]: any;
    label: string;
    labelEn: string;
    rentPrices: RentPrice[];
    additionalProductIds: string[];
}

export interface Banner {
    id: string;
    label: string;
    labelEn: string;
    startDate: Date;
    endDate: Date;
    promotion: boolean;
    promotionPct: number;
}

export interface User {
    id: string;
    roles: string[];
    phoneNumber: string;
    email: string;
    emailConfirmed: boolean;
    userName: string;
    gender: string;
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    city: string;
    postCode: string;
    country: string;
}

export interface Season {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    active: boolean;
}

export interface MoneticoParams {
    cgi: string;
    form: FormParameter;
}

export interface FormParameter {
    [key: string]: string;
}

export interface TableColumnDefinition {
    propertyName: string;
    label: string;
    type?: string;
    onChange?: (row: any, value: any) => void;
}

export interface Country {
    [key: string]: string;

    id: string;
    name: string;
    nameEn: string;
}

export interface CodeOrders {
    code: string;
    orders: Order[];
    totalPrice: number;
}

export interface Language {
    id: string;
    nameKey: string;
}

export interface Toast {
    id: string;
    icon: string;
    type: string;
    title: string;
    description: string;
}

export enum ProductType {
    AdultSkis = 1,
    ChildrenSkis = 2,
    Snowboards = 3,
    Monoskis = 4,
    Boots = 5,
    ChildrenSnowboards = 6
}

export enum DepositState {
    None = 0,
    InProgress = 1,
    Successfull = 2,
    Failed = 3
}

export default Order;